import React from 'react'
import './services.css'
import { TbScubaMask } from 'react-icons/tb'
import { IoIosFitness } from 'react-icons/io'
import { FaMusic } from 'react-icons/fa'
import { TbSwimming } from 'react-icons/tb'
import { GiRollerSkate } from 'react-icons/gi'
import { FaRunning } from 'react-icons/fa'
import { AiOutlineFundProjectionScreen } from 'react-icons/ai'
import { RiTeamFill } from 'react-icons/ri'
import { BsFillPersonCheckFill } from 'react-icons/bs'
import { FcDepartment } from 'react-icons/fc'
import { BsCodeSlash } from 'react-icons/bs'
import { FaAward } from 'react-icons/fa'

const Services = () => {
  return (
    <section id='services'>
      <h5>Was ich suche/<wbr></wbr>Meine Hobbies</h5>
     <h2>Services</h2> 

      <div className='container services__container'>
        <article className='service'>
          <div className='service__head'>
            <h3>Was ich suche</h3>
          </div>

          <ul className='service__list'>
            <li>
              <AiOutlineFundProjectionScreen className='service__list-icon'/>
              <p>IT-Projekt&shy;management</p>
            </li>
            <li>
              <RiTeamFill className='service__list-icon'/>
              <p>IT-<wbr></wbr>Teamleitung</p>
            </li>
            <li>
              <AiOutlineFundProjectionScreen className='service__list-icon'/>
              <p>IT-<wbr></wbr>Projektleitung</p>
            </li>
            <li>
              <BsFillPersonCheckFill className='service__list-icon'/>
              <p>IT-<wbr></wbr>Consulting</p>
            </li>
            <li>
              <FcDepartment className='service__list-icon'/>
              <p>Abteilungs&shy;leitung</p>
            </li>
            <li>
              <BsCodeSlash className='service__list-icon'/>
              <p>Software&shy;entwicklung</p>
            </li>
          </ul>
        </article>
        {/*END OF What I am looking for*/}

        <article className='service'>
          <div className='service__head'>
            <h3>Meine Hobbies</h3>
          </div>

          <ul className='service__list'>
            <li>
              <IoIosFitness className='service__list-icon'/>
              <p>Sport</p>
            </li>
            <li>
              <GiRollerSkate className='service__list-icon'/>
              <p>Inliner fahren/<wbr></wbr>skaten</p>
            </li>
            <li>
              <FaRunning className='service__list-icon'/>
              <p>Joggen</p>
            </li>
              <li>
              <TbScubaMask className='service__list-icon'/>
              <p>Schnorcheln/<wbr></wbr>Tauchen</p>
            </li>
            <li>
              <TbSwimming className='service__list-icon'/>
              <p>Schwimmen</p>
            </li>
            <li>
              <FaMusic className='service__list-icon'/>
              <p>Tanzen (Standard und Latein)</p>
            </li>
            <li>
              <BsCodeSlash className='service__list-icon'/>
              <p>Programmieren</p>
            </li>
            <li>
              <FaAward className='service__list-icon'/>
              <p>Udemy-Kurse</p>
            </li>
          </ul>
        </article>
        {/*END OF HOBBIES*/}
      </div>
    </section>
  )
}

export default Services