import React from 'react'
import {FaXingSquare} from 'react-icons/fa'
import {FaGithub} from 'react-icons/fa'
import {FaFacebookSquare} from 'react-icons/fa'
import {FaInstagramSquare} from 'react-icons/fa'

const HeaderSocials = () => {
  return (
    <div className='header__socials'>
        <a href='https://www.xing.com/profile/Yannick_Radke' target="_blank"><FaXingSquare/></a>
        <a href='https://www.facebook.com/yannick.rdk/' target="_blank"><FaFacebookSquare/></a>
        <a href='https://www.instagram.com/yannick_beutlin/' target="_blank"><FaInstagramSquare/></a>
        <a href='https://github.com/YK18415' target="_blank"><FaGithub/></a>
    </div>
  )
}

export default HeaderSocials