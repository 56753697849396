import React from 'react'
import './header.css'
import CTA from './CTA'
import ME from '../../assets/Me_Transparent.png'
import HeaderSocials from './HeaderSocials'

const Header = () => {
  return (
    <header>
      <div className="container header__container">
        <h5>Hallo, ich bin</h5>
        <h1>Yannick Radke, M.Sc.</h1>
        <h5 className='text-light'>Junior IT-Projektmanager/IT-Consultant</h5>
        <CTA />
        <HeaderSocials />
      <div className='me'>
        <img src={ME} alt='me'/>
      </div>

      <a href='#contact' className='scroll__down'>Kontaktieren Sie mich</a>
      
      </div>
      </header>
  )
}

export default Header