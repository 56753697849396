import React from 'react'
import './values.css'
import MitarbeiterImage from '../../assets/Mitarbeiter.png'
import ZuverlaessigkeitImage from '../../assets/Zuverlaessigkeit_Vertrauenswuerdig.jpg'
import EhrgeizImage from '../../assets/Ehrgeizig.jpg'
import EhrlichkeitImage from '../../assets/Ehrlichkeit.jpg'
import WertschätzungImage from '../../assets/Wertschaetzung.jpg'
import HilfsbereitschaftImage from '../../assets/Zusammenhalt.jpg'
import DelegierenImage from '../../assets/Delegieren.jpg'
import KommuniaktionImage from '../../assets/Kommunikation_Reden.jpg'
import WorkLifeBalanceImage from '../../assets/work_life_balance.png'
import FamilieImage from '../../assets/Familie.jpg'

// import Swiper core and required modules
import { Pagination } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

const data = [
	{
		valueImage: MitarbeiterImage,
		name: 'Mitarbeiter/Menschen',
    description: 'Die Mitarbeiter stehen im Vordergrund. Sie sind der Kern eines Unternehmens. Ein Unternehmen ist nur so gut wie dessen Mitarbeiter.'
	},
  {
		valueImage: ZuverlaessigkeitImage,
		name: 'Zuverlässigkeit',
    description: 'Aufgaben werden stets gewissenhaft und pünktlich bearbeitet.'
	},
  {
		valueImage: EhrgeizImage,
		name: 'Ehrgeiz',
    description: 'Aufgaben qualitativ hochwertig bearbeiten und eine leitende Position anstreben.'
	},
  {
		valueImage: EhrlichkeitImage,
		name: 'Ehrlichkeit',
    description: 'Stets einen loyalen Umgang mit den Mitarbeitern und Kollegen pflegen.'
	},
  {
		valueImage: WertschätzungImage,
		name: 'Wertschätzung',
    description: 'Wertschätzung der Leistungen der Mitarbeiter.'
	},
  {
		valueImage: HilfsbereitschaftImage,
		name: 'Hilfsbereitschaft',
    description: 'Mitarbeitern und Kollegen stets in Rat und Tat zur Seite stehen.'
	},
  {
		valueImage: DelegierenImage,
		name: 'Delegieren (aber richtig)',
    description: 'Als Führungskraft ist es die Aufgabe Mitarbeiter zu fördern und zu fordern. Ihnen individuell ihrer Qualifikation entsprechend Aufgaben delegieren, sodass der Führungskraft genug Zeit für ihre Fühungsaufgaben bleibt, ist enorm wichtig.'
	},
  {
		valueImage: KommuniaktionImage,
		name: 'Kommunikation',
    description: 'Ein Großteil aller Projekte scheitert aufgrund mangelhafter Kommunikation. Das Hineinschauen in die Köpfe anderer Personen ist eine Kunst, die gelernt sein will.'
	},
  {
		valueImage: WorkLifeBalanceImage,
		name: 'Work Life Balance',
    description: 'Jeder muss mal Abschalten können. Dies ist wichtig, vor allem, um neue Kraft zu schöpfen.'
	},
  {
		valueImage: FamilieImage,
		name: 'Familie',
    description: 'Arbeit ist wichtig, jedoch geht nichts über die eigene Familie. Sie ist das Wichtigste im Leben und darf niemals vernachlässigt werden.'
	},
]

const Values = () => {
  return (
    <section id='values'>
      <h5>Meine Werte</h5>
      <h2>Meine Überzeugungen</h2>

      <Swiper className='container values__container'
       // install Swiper modules
       modules={[Pagination]}
       spaceBetween={40} /*40px*/
       slidesPerView={1}
       pagination={{ clickable: true }}
       >
        {
          data.map(({valueImage, name, description}, index) => {
            return (
              <SwiperSlide key={index} className='value'>
                <div className='value__image'>
                  <img src={valueImage} />
                </div>
                <h5 className='value__name'>{name}</h5>
                <small className='value__description'>{description}</small>
              </SwiperSlide>
            )
          })
        }

      </Swiper>
    </section>
  )
}

export default Values