import { React } from 'react'
import './contact.css'
import {MdOutlineEmail }from 'react-icons/md'
import { RiMessengerLine } from 'react-icons/ri'
import { useState, useRef } from 'react';
import emailjs from 'emailjs-com';

const Contact = () => {

  const [sended, setSended] = useState(false);

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_e3f646a', 'template_8zvyjme', form.current, 'zW8Kfo5WalHOypHso')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });

      e.target.reset();
  };

  const toggleState = (e) => {
    setSended(true);
  };

  return (
    <section id='contact'>
      <h5>Get In Touch</h5>
      <h2>Kontaktieren Sie mich</h2>

      <div className='container contact__container'>
        <div className='contact__options'>
          <article className='contact__option'>
            <MdOutlineEmail className='contact__image-icon'/>
            <h4>E-Mail</h4>
            <h5>info.yannickradke@gmail.com</h5>
            <a href="mailto:info.yannickradke@gmail.com" target="_blank">Senden Sie mir eine E-Mail</a>
          </article>

          <article className='contact__option'>
            <RiMessengerLine className='contact__image-icon'/>
            <h4>Messenger</h4>
            <h5>yannick.rdk</h5>
            <a href="https://m.me/yannick.rdk" target="_blank">Senden Sie mir eine Nachricht per Facebook</a>
          </article>
        </div>
        {/*END OF CONTACT OPTIONS*/}
        <form ref={form} onSubmit={(event) => {sendEmail(event); toggleState(event);}}>
          <input type="text" name='name' placeholder='Ihr Name' required/>
          <input type="email" name='email' placeholder='Ihre E-Mail-Adresse'/>
          <textarea name="message" rows="7" placeholder='Ihre Nachricht' required></textarea>
          <button type='submit' className='btn btn-primary'>Nachricht abschicken</button>
          {sended && <h2>Ihre Nachricht wurde verschickt</h2>}
        </form>
      </div>
    </section>
  )
}

export default Contact