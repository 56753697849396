import React from 'react'
import './about.css'
import ME from '../../assets/Me.jpg'
import {FaAward} from 'react-icons/fa'
import {FiUsers} from 'react-icons/fi'
import {VscFolderLibrary} from 'react-icons/vsc'

const About = () => {
  return (
    <section id='about'>
      <h5>Wissenswertes</h5>
      <h2>Über mich</h2>

      <div className='container about__container'>
        <div className='about__me'>
          <div className='about__me-image'>
            <img src={ME} alt='About image'/>
          </div>
        </div>

        <div className='about__content'>
          <div className='about__cards'>
            <article className='about__card'>
              <FaAward className='about__icon'/>
              <h5>Ausbildung/<wbr></wbr>Studium</h5>
              <small>
                <li>2021 - 2023: Studium Wirtschafts&shy;informatik Master (Online) (M.Sc.)</li>
                <li>2016 - 2020: Duales Studium Informatik (B.Sc.)</li>
                <li>2016 - 2018: Ausbildung zum Fach&shy;informatiker für Anwendungs&shy;entwicklung</li>
                <li>2003 - 2016: Abitur</li>
              </small>
            </article>

            <article className='about__card'>
              <FiUsers className='about__icon'/>
              <h5>Berufs&shy;erfahrung</h5>
              <small>
                <li>2023 - Heute: Junior IT-Projektmanager/IT-Consultant</li>
                <li>2021 - 2023: Studentischer Mitarbeiter</li>
                <li>2020 - 2021: System&shy;analytiker</li>
                <li>2016 - 2020: Duales Studium Informatik</li>
              </small>
            </article>

            <article className='about__card'>
              <VscFolderLibrary className='about__icon'/>
              <h5>Projekte</h5>
              <small>5 plus Studienprojekte</small>
            </article>

          </div>
          <p>
            Das Abitur absolvierte ich an der freien Waldorf&shy;schule in Wolfsburg. Anschließend begann ich mein duales Informatik-Studium an der
            Ostfalia Hochschule für angewandte Wissenschaften bei der Sternico GmbH. Dies beinhaltete eine Ausbildung zum Fachinformatiker für
            Anwendungs&shy;entwicklung. Nachdem ich dies im März 2020 erfolgreich abschloss, wechselte ich als System&shy;analytiker zur
            Lineas Informations&shy;technik GmbH. Ein Jahr später entschloss ich mich dazu einen Master in Wirtschafts&shy;informatik ebenfalls an der
            Ostfalia Hochschule für angewandte Wissenschaften zu absolvieren. Den Abschluss erhielt ich 2023. Währenddessen war ich bei der Lineas Informations&shy;technik GmbH als
            studentischer Mitarbeiter angestellt. Aktuell arbeite ich als Junior IT-Projektmanager/IT-Consultant.
            </p>
          <a href='#contact' className='btn btn-primary'>Let's talk</a>
        </div>
      </div>
    </section>
  )
}

export default About