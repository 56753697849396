import React from 'react'
import './experience.css'
import {BsPatchCheckFill} from 'react-icons/bs'

const Experience = () => {
  return (
    <section id='experience'>
      <h5>Meine Skills</h5>
      <h2>Meine Erfahrungen</h2>

      <div className='container experience__container'>

        <div className='experience__hardskills'>
          <h3>Hard-Skills</h3>
          <div className='experience__content'>
            <article className='experience__details'>
              <BsPatchCheckFill className='experience__details-icon'/>
              <div>
                <h4>Java</h4>
                <small className='text-light'>Sehr fortgeschritten</small>
              </div>
            </article>

            <article className='experience__details'>
              <BsPatchCheckFill className='experience__details-icon'/>
              <div>
                <h4>Eclipse/<wbr></wbr>IntelliJ/<wbr></wbr>Visual Studio Code</h4>
                <small className='text-light'>Fortgeschritten</small>
              </div>
            </article>

            <article className='experience__details'>
              <BsPatchCheckFill className='experience__details-icon'/>
              <div>
                <h4>React</h4>
                <small className='text-light'>Fortgeschritten</small>
              </div>
            </article>

            <article className='experience__details'>
              <BsPatchCheckFill className='experience__details-icon'/>
              <div>
                <h4>Jira</h4>
                <small className='text-light'>Sehr fortgeschritten</small>
              </div>
            </article>

            <article className='experience__details'>
              <BsPatchCheckFill className='experience__details-icon'/>
              <div>
                <h4>Confluence</h4>
                <small className='text-light'>Anfänger bis fortgeschritten</small>
              </div>
            </article>

            <article className='experience__details'>
              <BsPatchCheckFill className='experience__details-icon'/>
              <div>
                <h4>SonarQube</h4>
                <small className='text-light'>Anfänger bis fortgeschritten</small>
              </div>
            </article>

            <article className='experience__details'>
              <BsPatchCheckFill className='experience__details-icon'/>
              <div>
                <h4>Jenkins</h4>
                <small className='text-light'>Anfänger</small>
              </div>
            </article>

            <article className='experience__details'>
              <BsPatchCheckFill className='experience__details-icon'/>
              <div>
                <h4>Selenium</h4>
                <small className='text-light'>Anfänger bis fortgeschritten</small>
              </div>
            </article>

            <article className='experience__details'>
              <BsPatchCheckFill className='experience__details-icon'/>
              <div>
                <h4>Spring-Framework</h4>
                <small className='text-light'>Anfänger bis fortgeschritten</small>
              </div>
            </article>

            <article className='experience__details'>
              <BsPatchCheckFill className='experience__details-icon'/>
              <div>
                <h4>HTML</h4>
                <small className='text-light'>Fortgeschritten</small>
              </div>
            </article>

            <article className='experience__details'>
              <BsPatchCheckFill className='experience__details-icon'/>
              <div>
                <h4>CSS</h4>
                <small className='text-light'>Anfänger bis fortgeschritten</small>
              </div>
            </article>

            <article className='experience__details'>
              <BsPatchCheckFill className='experience__details-icon'/>
              <div>
                <h4>Geschäfts&shy;prozess&shy;management</h4>
                <small className='text-light'>Anfänger bis fortgeschritten</small>
              </div>
            </article>

            <article className='experience__details'>
              <BsPatchCheckFill className='experience__details-icon'/>
              <div>
                <h4>BPMN 2.0</h4>
                <small className='text-light'>Fortgeschritten</small>
              </div>
            </article>

            <article className='experience__details'>
              <BsPatchCheckFill className='experience__details-icon'/>
              <div>
                <h4>Projekt&shy;management</h4>
                <small className='text-light'>Anfänger bis fortgeschritten</small>
              </div>
            </article>

            <article className='experience__details'>
              <BsPatchCheckFill className='experience__details-icon'/>
              <div>
                <h4>ProjectLibre</h4>
                <small className='text-light'>Anfänger bis fortgeschritten</small>
              </div>
            </article>
            
            <article className='experience__details'>
              <BsPatchCheckFill className='experience__details-icon'/>
              <div>
                <h4>Anforderungs&shy;management</h4>
                <small className='text-light'>Anfänger</small>
              </div>
            </article>
            
            <article className='experience__details'>
              <BsPatchCheckFill className='experience__details-icon'/>
              <div>
                <h4>UI-/UX-Design</h4>
                <small className='text-light'>Anfänger bis fortgeschritten</small>
              </div>
            </article>

            <article className='experience__details'>
              <BsPatchCheckFill className='experience__details-icon'/>
              <div>
                <h4>Andoid-<wbr></wbr>Entwicklung</h4>
                <small className='text-light'>Anfänger bis fortgeschritten</small>
              </div>
            </article>

            <article className='experience__details'>
              <BsPatchCheckFill className='experience__details-icon'/>
              <div>
                <h4>Microsoft Office</h4>
                <small className='text-light'>Sehr fortgeschritten</small>
              </div>
            </article>
          </div>
        </div>

        {/*END OF HARDSKILLS*/}

        <div className='experience__softskills'>
        <h3>Soft-Skills</h3>
        <div className='experience__content'>
            <article className='experience__details'>
              <BsPatchCheckFill className='experience__details-icon'/>
              <div>
                <h4>Einfühlungs&shy;vermögen/<wbr></wbr>Empathie</h4>
                <small className='text-light'>Sehr fortgeschritten</small>
              </div>
            </article>

            <article className='experience__details'>
              <BsPatchCheckFill className='experience__details-icon'/>
              <div>
                <h4>Mitarbeiter&shy;führung</h4>
                <small className='text-light'>Fortgeschritten</small>
              </div>
            </article>
            
            <article className='experience__details'>
              <BsPatchCheckFill className='experience__details-icon'/>
              <div>
                <h4>Agile Entwicklung</h4>
                <small className='text-light'>Sehr fortgeschritten</small>
              </div>
            </article>

            <article className='experience__details'>
              <BsPatchCheckFill className='experience__details-icon'/>
              <div>
                <h4>Durchhalte&shy;vermögen</h4>
                <small className='text-light'>Experte</small>
              </div>
            </article>

            <article className='experience__details'>
              <BsPatchCheckFill className='experience__details-icon'/>
              <div>
                <h4>Kritik&shy;fähigkeit</h4>
                <small className='text-light'>Fortgeschritten</small>
              </div>
            </article>

            <article className='experience__details'>
              <BsPatchCheckFill className='experience__details-icon'/>
              <div>
                <h4>Eigen&shy;initiative</h4>
                <small className='text-light'>Experte</small>
              </div>
            </article>

            <article className='experience__details'>
              <BsPatchCheckFill className='experience__details-icon'/>
              <div>
                <h4>Lern&shy;bereitschaft</h4>
                <small className='text-light'>Experte</small>
              </div>
            </article>

          </div>
        </div>

      </div>
    </section>
  )
}

export default Experience